<template>
  <div class="advantage">
    <div class="circle">
      <img
        :src="image"
        alt="icon"
        class="image"
      >
    </div>
    <div class="text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvantageSmall',
  props: {
    image: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
.advantage {
  display: flex;
  align-items: center;
  flex-direction: column;
  .circle {
    width: 80px;
    height: 80px;
    background: linear-gradient(135deg, #449DCB 0%, #76CFE7 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    flex-shrink: 0;
    .image {
      display: block;
      max-width: 50%;
    }
  }
  .text {
    margin-top: 20px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    line-height: 1.4;
  }
}
</style>
