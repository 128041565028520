<template>
  <div class="info">
    <div class="left">
      <p class="title">{{ title }}</p>
      <p class="text">{{ text }}</p>

      <BaseButton
        :href="href"
        variant="light"
        class="button"
      >
        Подробнее
      </BaseButton>
    </div>
    <div class="right">
      <img
        v-if="image"
        :src="image"
        alt="logo"
        class="logo"
      >

      <svg
        class="tracery"
        width="29"
        height="43"
        viewBox="0 0 29 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="20" width="6" height="6" fill="white"/>
        <rect x="8" y="14" width="8" height="8" fill="white"/>
        <rect x="23" y="29" width="6" height="6" fill="white"/>
        <rect y="34" width="9" height="9" fill="white"/>
      </svg>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton'
export default {
  name: 'AppInfo',
  components: { BaseButton },
  props: {
    title: String,
    text: String,
    image: String,
    href: {
      type: String,
      default: '#'
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  .left {
    height: 100%;
    padding: 20px;
    background: #fff;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.2;
    }
    .text {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.8;
      color: #333333;
    }
    .button {
      margin-top: auto;
      width: 90%;
      justify-content: center;
    }
  }
  .right {
    height: 100%;
    background: #006B9C;
    width: 70px;
    flex-shrink: 0;
    position: relative;
    .logo {
      width: 38px;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .tracery {
      position: absolute;
      bottom: 27px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
