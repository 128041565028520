<template>
  <section class="preview">
    <div class="container">
      <div class="preview__info">
        <p
          v-html="title"
          class="preview__title"
        />
        <p
          v-html="text"
          class="preview__text"
        />
      </div>
      <div class="preview__right">
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppPreview',
  props: {
    title: String,
    text: String,
    background: {
      type: String,
      default: 'linear-gradient(270deg, #0590D3 0%, #0066CB 100%)'
    }
  }
}
</script>

<style lang="scss" scoped>
$media-md:                                992px !default;

.preview {
  padding: 85px 0 120px;
  background: v-bind(background);

  @media screen and (max-width: $media-md) {
    padding: 40px 0 60px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $media-md) {
      flex-direction: column;
      justify-content: initial;
      align-items: initial;
    }
  }

  &__info {
    max-width: 452px;
    color: #fff;
  }

  &__title {
    font-size: 52px;
    font-weight: 500;
    line-height: 1;

    @media screen and (max-width: $media-md) {
      font-size: 32px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.40;
    margin-top: 20px;

    @media screen and (max-width: $media-md) {
      font-size: 14px;
    }
  }

  &__right {
    @media screen and (max-width: $media-md) {
      margin-top: 40px;
      align-self: center;
    }
  }
}
</style>
