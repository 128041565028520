<template>
  <div class="service">
    <div>
      <div>
        <div
          class="circle"
          :style="{ background: background }"
        >
          <img :src="image" alt="icon">
        </div>
      </div>

      <p class="title">
        {{ title }}
      </p>
      <p class="text">
        {{ text }}
      </p>
    </div>

    <div class="button-wrapper">
      <BaseButton
        block
        class="button"
        @click="$emit('request')"
      >
        Оставить заявку
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton'

export default {
  name: 'AppService',
  components: { BaseButton },
  props: {
    image: String,
    title: String,
    text: String,
    background: {
      type: String,
      default: 'linear-gradient(135deg, #449DCB 0%, #76CFE7 100%)'
    }
  },
  emits: {
    request: null
  }
}
</script>

<style lang="scss" scoped>
.service {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(209, 212, 224, 0.25), 0 0 60px #E4EAF1;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .title {
    font-weight: 600;
    font-size: 21px;
    margin-top: 30px;
  }
  .text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.5;
    color: #4D4D4D;
  }
  .button-wrapper {
    margin-top: auto;
  }
  .button {
    margin-top: 20px;
  }
}
</style>
