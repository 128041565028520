<template>
  <main class="home">
    <section class="swiper">
      <div class="swiper-wrapper">
        <AppPreview
          v-for="(slide, index) of slides"
          :key="index"
          :title="slide.title"
          :text="slide.text"
          :background="slide.background"
          class="swiper-slide"
        >
          <img :src="slide.image" alt="preview">
        </AppPreview>
      </div>

      <!-- If we need pagination -->
      <div class="swiper-pagination" />
    </section>

    <section class="description">
      <div class="container">
        <h2 class="description__title">Cloud bit – оптимизирует ваши затраты на «Железо»</h2>
        <p class="description__text">Мы избавим вас от работы по организации серверного помещения, закупки и настройки дорогостоящего серверного и сетевого оборудования, на поиск квалифицированного IT персонала.</p>
      </div>
    </section>

    <section class="components">
      <div class="container">
        <AppInfo
          v-for="(item, index) of info"
          :key="index"
          :title="item.title"
          :text="item.text"
          :image="item.image"
          :href="item.href"
        />
      </div>
    </section>

    <section class="approach">
      <div class="container">
        <h2>Подход Cloud bit</h2>
        <ul class="approach__list">
          <li
            v-for="(item, index) of approach"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </section>

    <section class="advantages-small">
      <div class="container">
        <AdvantageSmall
          v-for="(item, index) of advantagesMini"
          :key="index"
          :image="item.image"
          :text="item.text"
        />
      </div>
    </section>

    <section class="advantages">
      <div class="container">
        <h2>Преимущества выбора Cloud bit</h2>
        <div class="advantages__list">
          <AppAdvantage
            v-for="(advantage, index) of $store.getters.advantages"
            :key="index"
            :image="advantage.image"
            :title="advantage.title"
            :text="advantage.text"
          />
        </div>
      </div>
    </section>

    <section class="partners">
      <div class="container">
        <h2>Наши партнеры</h2>

        <div class="partners__list">
          <div
            v-for="(partner, index) of $store.getters.partners"
            :key="index"
            class="partners__item"
          >
            <img :src="partner">
          </div>
        </div>
      </div>
    </section>

    <section>
      <AppBanner
        @request="$refs.requestModal.open()"
      />
    </section>

    <section class="preview-services">
      <div class="preview">
        <div class="container">
          <h3 class="title-arrow">Дополнительные услуги</h3>

          <div class="content">
            <div class="left">
              <h1 class="title">Разработка<br>сайтов и приложений</h1>
              <p class="subtitle">Создание решений, которые работают. Качественная реализация. Талантливые дизайнеры. Соблюдение дедлайнов.</p>
              <BaseButton
                class="btn"
                @click="$refs.requestModal.open()"
              >
                Узнать подробнее
              </BaseButton>
            </div>

            <div class="right">
              <img src="/images/preview.png">
            </div>
          </div>
        </div>
      </div>

      <div class="services">
        <div class="container">
          <h3 class="title-arrow">Еще</h3>

          <div class="services__list">
            <AppService
              v-for="(service, index) of services"
              :key="index"
              :image="service.image"
              :title="service.title"
              :text="service.text"
              :background="service.background"
              @request="$refs.requestModal.open()"
            />
          </div>
        </div>
      </div>
    </section>

    <BaseModal
      ref="requestModal"
    >
      <div class="request-modal">
        <h2 class="request-modal__title">
          Оформление заявки
        </h2>
        <BaseInput
          placeholder="Фамилия"
          class="request-modal__input"
          v-model="request.surname"
        >
          <BaseError v-if="v$.request.$dirty && v$.request.surname.required.$invalid">
            {{ $store.state.vuelidate.required }}
          </BaseError>
        </BaseInput>
        <BaseInput
          placeholder="Имя"
          class="request-modal__input"
          v-model="request.name"
        >
          <BaseError v-if="v$.request.$dirty && v$.request.name.required.$invalid">
            {{ $store.state.vuelidate.required }}
          </BaseError>
        </BaseInput>
        <BaseInput
          placeholder="Отчество"
          class="request-modal__input"
          v-model="request.patron"
        >
          <BaseError v-if="v$.request.$dirty && v$.request.patron.required.$invalid">
            {{ $store.state.vuelidate.required }}
          </BaseError>
        </BaseInput>
        <BaseInput
          placeholder="Наименование компании"
          class="request-modal__input"
          v-model="request.companyName"
        >
          <BaseError v-if="v$.request.$dirty && v$.request.companyName.required.$invalid">
            {{ $store.state.vuelidate.required }}
          </BaseError>
        </BaseInput>
        <BaseInput
          placeholder="Введите ваш email"
          class="request-modal__input"
          v-model="request.email"
        >
          <BaseError v-if="v$.request.$dirty && v$.request.email.required.$invalid">
            {{ $store.state.vuelidate.required }}
          </BaseError>
          <BaseError v-if="v$.request.$dirty && v$.request.email.email.$invalid">
            {{ $store.state.vuelidate.email }}
          </BaseError>
        </BaseInput>
        <BaseInput
          placeholder="Контактный телефонный номер"
          class="request-modal__input"
          v-model="request.phone"
        >
          <BaseError v-if="v$.request.$dirty && v$.request.phone.required.$invalid">
            {{ $store.state.vuelidate.required }}
          </BaseError>
        </BaseInput>
        <BaseCheckbox
          v-model="request.checkbox"
          class="request-modal__checkbox"
          label="Я принимаю условия публичной оферты"
        >
          <template #error>
            <BaseError v-if="v$.request.$dirty && v$.request.checkbox.checked.$invalid">
              {{ $store.state.vuelidate.required }}
            </BaseError>
          </template>
        </BaseCheckbox>
        <AppCaptcha style="margin-top: 14px;" />
        <div>
          <BaseButton
            class="request-modal__btn"
            @click="onSubmit"
          >
            Оставить заявку
          </BaseButton>
        </div>
      </div>
    </BaseModal>

    <ModalThank ref="modalThank" />
  </main>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import Swiper, { Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

import AppPreview from '@/components/AppPreview'
import AppAdvantage from '@/components/AppAdvantage'
import AppInfo from '@/components/AppInfo'
import AdvantageSmall from '@/components/AdvantageSmall'
import AppService from '@/components/AppService'
import AppBanner from '@/components/AppBanner'
import BaseButton from '@/components/Base/BaseButton'
import BaseModal from '@/components/Base/BaseModal'
import BaseInput from '@/components/Base/BaseInput'
import BaseCheckbox from '@/components/Base/BaseCheckbox'
import BaseError from '@/components/Base/BaseError'
import ModalThank from '@/components/ModalTemplates/ModalThank'
import AppCaptcha from '@/components/AppCaptcha'

export default {
  name: 'PageHome',
  components: {
    AppCaptcha,
    ModalThank,
    BaseError,
    BaseCheckbox,
    BaseInput,
    BaseModal,
    AppPreview,
    AppAdvantage,
    AppInfo,
    AdvantageSmall,
    AppService,
    AppBanner,
    BaseButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      slides: [
        {
          title: 'Виртуальный 1С',
          text: 'Прямо в облаке. Всегда под рукой',
          image: '/images/preview-slide1.png',
          background: 'linear-gradient(269.77deg, #D4ECFF -0.25%, #5389C2 52.82%, #32497B 100.26%)'
        },
        {
          title: 'Резервное копирование',
          text: 'Обеспечьте безопасность, сохранность и оперативность доступа к данным',
          image: '/images/preview-slide2.png',
          background: '#131340'
        },
        {
          title: 'Переходи на облако',
          text: 'Построй свою идеальную инфраструктуру при помощи облачных технологий',
          image: '/images/preview-slide1.png',
          background: '#131340'
        },
        {
          title: 'Перенос сервисов',
          text: 'Последовательно перенесем нужные сервисы в облако. Сведем к минимуму время недоступности информационных систем.',
          image: '/images/preview-slide4.png',
          background: 'linear-gradient(269.77deg, #D4ECFF -0.25%, #5389C2 52.82%, #32497B 100.26%)'
        }
      ],
      info: [
        {
          title: 'Инфраструктура в облаке',
          text: 'Серверная и сетевая инфраструктура любой сложности с возможностью синхронизации вашей серверной',
          href: '/infrastructure',
          image: '/images/cmp1.svg'
        },
        {
          title: 'VDS',
          text: 'Ваш сервер любой конфигурации на отказоустойчивой площадке',
          href: '/vds',
          image: '/images/cmp2.svg'
        },
        {
          title: 'Виртуальный АТС',
          text: 'Пользуйся корпоративной телефонией без первоначальных затрат на оборудование и специалистов',
          href: '/virtual-atc',
          image: '/images/cmp3.svg'
        },
        {
          title: 'Bitrix 24',
          text: 'Контролируйте процесс решения задач и проектов',
          href: '#',
          image: '/images/cmp4.svg'
        },
        {
          title: '1С Сервер',
          text: 'Аренда облачного сервера 1С в отказоустойчивом ЦОДе',
          href: '/onec',
          image: '/images/cmp5.svg'
        },
        {
          title: 'Облачное хранилище (облачный диск)',
          text: 'Перемещайте важные документы, файлы, видео, фото на наши сервера',
          href: '/cloud-store',
          image: '/images/cmp6.svg'
        }
      ],
      approach: [
        'Не переплачиваете за неиспользуемые ресурсы арендуя необходимое количество',
        'Наращивание мощностей без потери времени и минимальными затратами',
        'Возможность развертывания любой сложности за короткий срок',
        'Отсутствие простоя за счет отказостойчивой инфраструктуры',
        'Минимальное содержание штата IT сотрудников'
      ],
      advantagesMini: [
        { text: '7 ЦОДов уровня TIER-3', image: '/images/medal.svg' },
        { text: 'SLA с финансовыми гарантиями', image: '/images/gramota.svg' },
        { text: 'Техподдержка 24/7', image: '/images/headphones.svg' }
      ],
      services: [
        {
          id: 1,
          image: '/images/service1.svg',
          title: 'Миграция сервисов',
          text: 'Последовательно мигрируем нужные сервисы в облако. Сведем к минимуму время недоступности систем.',
          background: 'linear-gradient(135deg, #449DCB 0%, #76CFE7 100%)'
        },
        {
          id: 2,
          image: '/images/service2.svg',
          title: 'Аутсорсинг',
          text: 'Экономьте время на поиске сотрудников и деньги на содержание штата сотрудников.',
          background: 'linear-gradient(135deg, #319FA5 0%, #30BCB2 100%)'
        },
        {
          id: 3,
          image: '/images/service3.svg',
          title: 'Hosting сайтов',
          text: 'Разместите свой сайт на быстром и удобном хостинг портале.',
          background: 'linear-gradient(135deg, #2CB0D9 0%, #3D96EB 100%)'
        },
        {
          id: 4,
          image: '/images/service4.svg',
          title: 'Резервное копирование',
          text: 'Обеспечим быстрое и надежное резервное копирование данных и  системы целиком.',
          background: 'linear-gradient(135deg, #3D8CEF 0%, #174CB8 100%)'
        }
      ],
      request: {
        surname: '',
        name: '',
        patron: '',
        companyName: '',
        email: '',
        phone: '',
        checkbox: false
      }
    }
  },
  mounted () {
    new Swiper('.swiper', {
      loop: true,
      speed: 600,
      modules: [Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
  },
  methods: {
    async onSubmit () {
      if (this.v$.request.$invalid) {
        this.v$.request.$touch()
        return
      }

      try {
        await this.$api.common.sendClientData({
          surname: this.request.surname,
          name: this.request.name,
          patronymic: this.request.patron,
          companyName: this.request.companyName,
          email: this.request.email,
          phone: this.request.phone
        })

        this.$refs.requestModal.close()
        this.$refs.modalThank.open()
      } catch (e) {
        console.log(e)
        alert('Произошла ошибка!')
      }
    }
  },
  validations: {
    request: {
      surname: {
        required
      },
      name: {
        required
      },
      patron: {
        required
      },
      companyName: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      checkbox: {
        checked: val => !!val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .description{
    padding: 40px 0 100px;
    //&__title {}
    &__text {
      margin-top: 40px;
    }
  }

  .components {
    padding: 77px 0;
    background-image: url('/images/components-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media screen and (max-width: $media-md) {
      background: #E9EBED;
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      grid-row-gap: 50px;
      grid-column-gap: 30px;
      grid-auto-rows: minmax(350px, auto);

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 30px;
        grid-column-gap: 30px;
      }

      @media screen and (max-width: $media-sm) {
        grid-template-columns: repeat(1, 350px);
        justify-content: center;
      }

      @media screen and (max-width: $media-esm) {
        grid-template-columns: repeat(1, 320px);
        justify-content: center;
      }
    }
  }

  .approach {
    padding: 80px 0 100px;
    &__list {
      margin-top: 40px;
      li {
        font-size: 20px;
        margin: 10px 0;

        @media screen and (max-width: $media-esm) {
          font-size: 16px;
        }
      }
    }
  }

  .advantages-small {
    padding: 70px 0;
    background-image: url('/images/advantage-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media screen and (max-width: $media-sm) {
      background: #005485;
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 225px);
      justify-content: space-between;

      @media screen and (max-width: $media-md) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
      }

      @media screen and (max-width: $media-sm) {
        grid-template-columns: repeat(1, 250px);
        justify-content: center;
      }
    }
  }

  .advantages {
    padding: 80px 0;
    &__list {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      grid-auto-rows: 350px;

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: $media-md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $media-esm) {
        justify-content: center;
        grid-template-columns: repeat(1, 280px);
      }
    }
  }

  .partners {
    padding: 70px 0 150px;

    @media screen and (max-width: $media-sm) {
      padding: 10px 0 70px;
    }

    &__list {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(5, 220px);
      justify-content: space-between;
      grid-auto-rows: 120px;

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: $media-sm) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .preview-services {
    background:
      url('/images/cloud-bg.png') no-repeat left 419px,
      url('/images/circle-bg.png') no-repeat right bottom 180px;

    @media screen and (max-width: $media-lg) {
      background-size: 0;
    }

    .title-arrow {
      position: relative;
      display: inline-block;

      &:after {
        content: '';
        width: 22px;
        height: 38px;
        background-image: url('/images/arrow-broken.svg');
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        right: -30px;
        top: 50%;
      }
    }
  }

  .preview {
    padding: 40px 0;
    position: relative;
    .content {
      margin-top: 100px;
      display: flex;

      @media screen and (max-width: $media-lg) {
        flex-direction: column;
        margin-top: 50px;
      }

      .left {
        flex-shrink: 0;
        .subtitle {
          font-size: 18px;
          line-height: 1.4;
          margin-top: 40px;
          max-width: 452px;
        }
        .btn {
          margin-top: 120px;
          //padding-left: 50px !important;
          //padding-right: 50px !important;

          @media screen and (max-width: $media-lg) {
            margin-top: 50px;
          }
        }
      }
      .right {
        margin-top: -90px;

        @media screen and (max-width: $media-lg) {
          width: 80%;
          margin: 70px auto 0;
        }

        @media screen and (max-width: $media-esm) {
          width: 100%;
          margin: 70px auto 0;
        }

        img {
          width: 1080px;

          @media screen and (max-width: $media-lg) {
            width: 100%;
          }
        }
      }
    }
  }

  .services {
    padding-top: 80px;

    @media screen and (max-width: $media-lg) {
      padding-top: 0;
    }

    &__list {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      grid-auto-rows: minmax(440px, auto);

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $media-esm) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.request-modal {
  width: 560px;

  @media screen and (max-width: $media-sm) {
    width: 100%;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__input {
    margin-bottom: 16px;
  }

  &__btn {
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
.home {
  .swiper {
    &-slide {
      height: 450px;
      align-items: center;
      display: flex;

      @media screen and (max-width: $media-md) {
        height: 550px;
      }

      @media screen and (max-width: $media-esm) {
        img {
          display: block;
          max-width: 300px;
        }
      }
    }

    &-pagination {
      background: rgba(0, 0, 0, 0.3);
      bottom: 0;
      padding: 10px 0;

      &-bullet {
        border: 2px solid #FFFFFF;
        background: transparent;
        width: 12px;
        height: 12px;
        opacity: 1;

        &-active {
          background: #fff;
        }
      }
    }
  }
}
</style>
